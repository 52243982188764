import { graphql } from 'gatsby'
import React from 'react'
import { MenuPrincipal } from '../../specific/constants/menu'
import { useSelectedMenu } from '../../specific/helpers/customHooks/useSelectedMenu'
import Layout from '../components/Layout'
import { SeoData } from '../types/Seo'
import PoliticaPrivacidadContents from './../../specific/static/politica-privacidad'
import styles from './politicaPrivacidad.module.scss'

interface Props {
  data: {
    seoData: SeoData
  }
}

export default function Index({ data }: Props) {
  useSelectedMenu(MenuPrincipal.HOME)
  return (
    <Layout selector={false} seoData={data.seoData}>
      <PoliticaPrivacidadContents styles={styles} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query politicaPrivacidadQuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
