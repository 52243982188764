import React from 'react'

interface Props {
  styles: { [k: string]: string }
}

const PoliticaPrivacidadPt = ({ styles }: Props) => (
  <div className={styles.container}>
    <h1 className={styles.title}>Política de Privacidade</h1>
    <p>
    A confidencialidade e a segurança são valores fundamentais da Rodi Motor Covipneus e, consequentemente, assumimos o compromisso de garantir a privacidade do Utilizador em todos os momentos e de não recolher informações desnecessárias. Abaixo, encontrará todas as informações necessárias sobre a nossa Política de Privacidade em relação aos dados pessoais que recolhemos, explicando-lhe:
    </p>
    <ul className={styles.list}>
      <li>Quem é o responsável pelo tratamento dos seus dados.</li>
      <li>Para que fins recolhemos os dados solicitados.</li>
      <li>Qual é a legitimidade para o seu tratamento.</li>
      <li>Quanto tempo nós os conservamos.</li>
      <li>Para quais destinatários os seus dados são comunicados.</li>
      <li>Quais são os seus direitos.</li>
    </ul>
    <h2 className={styles.section_title}>
      <strong>1.&nbsp;Responsável</strong>:
    </h2>
    <p>COVIPNEUS LDA (NIPC: 500228264)</p>
    <p>Zona Industrial do Fundão Lote 4, Fundão</p>
    <p>6230 483 FUNDÃO, PORTUGAL</p>
    <p>Email: apoioaocliente@rodimotor.pt</p>
    <h2 className={styles.section_title}>
      <strong>
        2. Finalidades, legitimação e preservação dos tratamentos dos dados enviados através de:
      </strong>
    </h2>
    <ul className={styles.list}>
      <li>
        <strong>Formulário de contacto.</strong>
      </li>
    </ul>
    <p>
      <strong>Finalidade:</strong> Fornecer um meio para que possa entrar em contacto connosco e possamos responder às suas solicitações de informações, bem como enviar comunicações dos nossos produtos, serviços e atividades, inclusive por meios eletrónicos, em caso de que selecione a caixa de aceitação.
    </p>
    <p>
      <strong>Legitimação:</strong> O consentimento do utilizador ao solicitar informações através do nosso formulário de contacto e ao selecionar a caixa de aceitação para o envio de informações.
    </p>
    <p>
      <strong>Conservação:</strong> Até que a sua solicitação através do nosso formulário tenha sido resolvida ou respondida por correio eletrónico, caso não se tenha gerado um novo tratamento. Em caso de que tenha aceitado receber comunicações comerciais, até que solicite o cancelamento das mesmas.
    </p>
    <ul className={styles.list}>
      <li>
        <strong>FORMULÁRIO DE MARCAÇÃO PRÉVIA.</strong>
      </li>
    </ul>
    <p>
      <strong>Finalidade:</strong> Fornecer um meio para fazer a marcação de um serviço nas nossas oficinas, bem como enviar comunicações dos nossos produtos, serviços e atividades, inclusive por meios eletrónicos, em caso de que selecione a caixa de aceitação.
    </p>
    <p>
      <strong>Legitimação:</strong>O consentimento do utilizador ao solicitar informações através do nosso formulário de marcação prévia e ao selecionar a caixa de aceitação para o envio de informações.
    </p>
    <p>
      <strong>Conservação:</strong> Até a data da marcação, caso não tenha sido gerado um novo tratamento. Em caso de que tenha aceitado receber comunicações comerciais, até que solicite o cancelamento das mesmas.
    </p>
    <ul className={styles.list}>
      <li>
        <strong>ENVIO DE CORREIOS ELETRÓNICOS.</strong>
      </li>
    </ul>
    <p>
      <strong>Finalidade:</strong> Responder às suas solicitações de informações, atender às suas petições e responder às suas consultas ou dúvidas.
    </p>
    <p>
      <strong>Legitimação:</strong>O consentimento do utilizador ao solicitar informações através do endereço de correio eletrónico.
    </p>
    <p>
      <strong>Conservação:</strong> Depois que sua solicitação tenha sido respondida por correio eletrónico, caso não se tenha gerado um novo tratamento.
    </p>
    <ul className={styles.list}>
      <li>
        <strong>ENVIO DE CURRICULUM VITAE POR CORREIO ELETRÓNICO.</strong>
      </li>
    </ul>
    <p>
      <strong>Finalidade:</strong> Dispor do seu currículo para participação nos nossos processos de seleção de pessoal.
    </p>
    <p>
      <strong>Legitimação:</strong> O consentimento do utilizador ao enviar as suas informações pessoais e currículo para nossos processos de seleção de pessoal.
    </p>
    <p>
      <strong>Conservação:</strong> Durante o desenvolvimento dos processos de seleção de pessoal abertos e por um 1 ano para futuros processos.
    </p>
    <ul className={styles.list}>
      <li>
        <strong>SUBSCRIÇÃO À NOSSA NEWSLETTER </strong>
      </li>
    </ul>
    <p>
      <strong>Finalidade:</strong> Envio de nosso boletim comercial e de comunicações informativas e publicitárias sobre os nossos produtos ou serviços do seu interesse, inclusive por meio eletrónico.
    </p>
    <p>
      <strong>Legitimação:</strong> O consentimento do utilizador ao subscrever-se aos nossos envios comerciais e/ou newsletters.
    </p>
    <p>
      <strong>Conservação:</strong> Até que o interessado revogue o consentimento e solicite o cancelamento do serviço.
    </p>
    <p>
      <strong>
      Obrigação de facultar-nos os seus dados pessoais e consequências de não fazê-lo.
      </strong>
    </p>
    <p>
    O fornecimento de dados pessoais requer uma idade mínima de 14 anos ou, quando apropriado, capacidade jurídica suficiente para contratar.
    </p>
    <p>
    Os dados pessoais solicitados são necessários para gerir as suas solicitações e/ou fornecer os serviços que possa contratar; portanto, se não os fornecer, não poderemos atendê-lo corretamente ou fornecer o serviço solicitado.
    </p>
    <h2 className={styles.section_title}>
      <strong>3. Destinatários dos seus dados</strong>
    </h2>
    <p>
    Os seus dados são confidenciais e não serão transferidos a terceiros, a menos que exista uma obrigação legal.
    </p>
    <p>
    Os seus dados podem ser transferidos para outras empresas do grupo Rodi para atender propósitos próprios.
    </p>
    <h2 className={styles.section_title}>
      <strong>4. Direitos em relação aos seus dados pessoais</strong>
    </h2>
    <p>
    Qualquer pessoa pode retirar o seu consentimento a qualquer momento, quando o mesmo tenha sido concedido para o tratamento dos seus dados. Em nenhum caso, a retirada deste consentimento condiciona a execução do contrato de adesão ou as relações geradas anteriormente.
    </p>
    <p>Igualmente, pode exercer os seguintes direitos:</p>
    <ul className={styles.list}>
      <li>
      Solicitar o acesso aos seus dados pessoais ou sua retificação quando forem imprecisos.
      </li>
      <li>
      Solicitar a sua exclusão, quando, entre outros motivos, os dados deixarem de ser necessários para as finalidades para as quais foram recolhidos.
      </li>
      <li>
      Solicitar a limitação do seu tratamento em determinadas circunstâncias.
      </li>
      <li>
      Solicitar a oposição ao tratamento dos seus dados por motivos relacionados com a sua situação particular.
      </li>
      <li>
      Solicitar a portabilidade dos dados nos casos previstos na regulamentação.
      </li>
      <li>Outros direitos reconhecidos nas regulamentações aplicáveis.</li>
    </ul>
    <p>
    Onde e como solicitar os seus direitos: Mediante um requerimento dirigido ao endereço postal ou eletrónico do responsável (indicado na secção A), indicando a referência “Dados Pessoais”, especificando o direito que deseja exercer e em relação a quais dados pessoais.
    </p>
    <p>
    Em caso de divergências com a empresa em relação ao tratamento dos seus dados, pode registar uma reclamação junto à autoridade responsável pela proteção de dados (www.cnpd.pt).
    </p>
    <h2 className={styles.section_title}>
      <strong>5. Cookies</strong>
    </h2>
    <p>
    Esta página Web usa apenas cookies técnicos, de personalização e análise, próprios e de terceiros (Google Analytics), que em nenhum caso tratam de dados pessoais ou capturam hábitos de navegação para fins publicitários.
    </p>
    <p>
    Portanto, ao aceder à nossa página Web, nos termos do artigo 22 da lei de serviços da sociedade da informação, ao tratar cookies de análise, solicitamos o seu consentimento para a utilização e fornecimento de informações dos mesmos, que em todos os casos, serão instalados após um período de tempo razoável, para que o utilizador tenha tempo de decidir se presta o seu consentimento ou não.
    </p>
    <h2 className={styles.section_title}>
      <strong>6. Segurança dos seus dados pessoais</strong>
    </h2>
    <p>
    Com o objetivo de salvaguardar a segurança dos seus dados pessoais, informamos que adotamos todas as medidas técnicas e organizacionais necessárias para garantir a segurança dos dados pessoais fornecidos em relação à sua alteração, perda e tratamentos ou acessos não autorizados.
    </p>
    <h2 className={styles.section_title}>
      <strong>7. Atualização dos seus dados</strong>
    </h2>
    <p>
    É importante que, para que possamos manter os seus dados pessoais atualizados, nos informe sempre que houver alguma modificação nos mesmos, caso contrário, não nos responsabilizamos pela sua veracidade.
    </p>
    <p>
    Não nos responsabilizamos pela política de privacidade em relação aos dados pessoais que possa fornecer a terceiros através das ligações disponíveis na nossa página Web.
    </p>
    <p>
    A presente Política de Privacidade pode ser modificada para adaptá-la às mudanças que ocorram na nossa página Web, bem como às eventuais modificações legislativas ou jurisprudenciais sobre os dados pessoais que surjam, por isso é requerida a sua leitura todas as vezes que fornecer as suas informações através desta página Web.
    </p>
  </div>
)

const PoliticaPrivacidadContents = ({ ...props }: Props) => (
  <PoliticaPrivacidadPt {...props} />
)

export default PoliticaPrivacidadContents
